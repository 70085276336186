(function ($) {
  var residence;

  function isMobileDevice() {
    return typeof window.orientation !== "undefined" || navigator.userAgent.indexOf('IEMobile') !== -1;
  }
  /* If the SCRIPT_DEBUG constant is defined in the wp-config */


  function console_log_debug(message) {
    if (residence_locator_data.debug) {
      console.log('[Residence Locator] ' + message);
    }
  }
  /* Populate underscoreJS template from residence data */


  function populate_residence_data(residence_data) {
    $('#current_residence_manual_search').hide();
    $('#find_another_residence').show();
    $('#current_residence_content').show(); // Clean and populate template here

    $('.localization-option').html(residence_data.residence_title);
    $('#residence_title').html(residence_data.residence_title);
    $('#residence_street_number').html(residence_data.residence_street_number);
    $('#residence_street_name').html(residence_data.residence_street_name);
    $('#residence_post_code').html(residence_data.residence_post_code);
    $('#residence_state').html(residence_data.residence_state);
    $('#residence_country').html(residence_data.residence_country);
    $('#residence_url').attr('href', residence_data.residence_url);
    $('#residence_phone').html(residence_data.residence_phone);
    $('#residence_fax').html(residence_data.residence_fax);
    $('#residence_opening_hours').html(residence_data.residence_opening_hours);
  }
  /**
   * If there is a field for residence location, we search right at load to order residences from closest.
   * 
   * @param {string} lat - User latitude.
   * @param {string} lng - User longitude
   */


  function pre_search_closest_residence_from_coordinates(latitude, longitude) {
    // Check on ready for Facetwp location search if there and populate the FacetWP aswell.
    $(document).ready(function () {
      if ('undefined' !== typeof FWP && 'undefined' !== typeof FWP.facets.fwp_residence_search) {
        if (!FWP.facets.fwp_residence_search.length) {
          FWP.facets.fwp_residence_search[0] = latitude;
          FWP.facets.fwp_residence_search[1] = longitude;
          FWP.facets.fwp_residence_search[2] = 250; // Search distance

          FWP.fetch_data();
        }
      }
    });
  }
  /* Theses two functions are made to define a expiration to the local storage.  */


  function setWithExpiry(key, value, ttl) {
    var now = new Date(); // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire

    var item = {
      value: value,
      expiry: now.getTime() + ttl
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  function getWithExpiry(key) {
    var itemStr = localStorage.getItem(key); // if the item doesn't exist, return null

    if (!itemStr) {
      return null;
    }

    var item = JSON.parse(itemStr);
    var now = new Date(); // compare the expiry time of the item with the current time

    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key);
      return null;
    }

    return item.value;
  }
  /* Browser geolocation error handling */


  function handleLocationError(error) {
    switch (error.code) {
      case 3:
        //timeout
        console_log_debug("" + error.message); // $('#current_residence_content').empty();

        $('#current_residence_content').append(residence_locator_data.translations.location_timeout);
        $('#spinner').hide();
        window.location.href = residence_locator_data.residence_locator_page;
        break;

      case 2:
        //can't get data
        console_log_debug("" + error.message); // $('#current_residence_content').empty();

        $('#current_residence_content').append(residence_locator_data.translations.location_declined);
        $('#spinner').hide();
        break;

      case 1:
        /* Declined */
        console_log_debug("Position denied\n Display input for address searching"); // $('#current_residence_content').empty();

        $('#current_residence_manual_search').show();
        $('#spinner').hide();
        break;

      case 0:
        /* other error */
        console_log_debug("There was an error while retrieving your location: " + error.message);
        window.location.href = residence_locator_data.residence_locator_page;
        break;
    }
  }
  /* If we get the Lng and Lat coordinates, we get back the closest residence data from the rest endpoint */


  function get_closest_residence_from_coordinates(latitude, longitude) {
    window.jQuery.getJSON('/wp-json/closest_residence_locator/coordinates/' + latitude + '/' + longitude + '/1/' + residence_locator_data.site_lang + '/', function (response) {
      if (0 !== response[0].length) {
        residence = response[0];
        $('#spinner').hide();
        populate_residence_data(residence);
        $('#current_residence_content').show();
        pre_search_closest_residence_from_coordinates(latitude, longitude);
        document.cookie = "closest_residence_id=" + residence.residence_id;
        setWithExpiry('closest_residence_' + residence_locator_data.site_lang, JSON.stringify(residence), 86400000);
        /* expire in one day */

        FWP.hooks.doAction('updated_closest_residence');
        console_log_debug('Gotcha! \n' + residence.residence_title + ' is the closest residence from the browser location.');
      } else {
        console_log_debug('Error from the API. We do nothing.');
      }
    }).fail(function (fail) {
      console_log_debug('Can\'t locate a residence from this position:\n' + fail.responseJSON[0] + '\nRedirecting to the location residence page.');
      window.location.href = residence_locator_data.residence_locator_page;
    });
  }
  /* If no residence are residenced in the browser, we get this endpoint. He will get the IP, find the location and send back the closest residence */


  function get_closest_residence_from_auto_ip() {
    window.jQuery.getJSON('/wp-json/closest_residence_locator/auto/1/' + residence_locator_data.site_lang + '/', function (response) {
      if (0 !== response[0].length) {
        var residence = response[0];
        populate_residence_data(residence);
        /**
         * @todo Faire en sorte que le response retourne aussi le LAT et LNG trouvé pour pouvoir exécuter la recherche FacetWP on the spot.
         */
        // pre_search_closest_residence_from_coordinates(latitude, longitude);

        document.cookie = "closest_residence_id=" + residence.residence_id + ";path=/";
        setWithExpiry('closest_residence_' + residence_locator_data.site_lang, JSON.stringify(residence), 86400000);
        /* expire in one day */

        console_log_debug('Closest residence get by IP');
      } else {
        console_log_debug('Fail to find the closest residence from the user IP:\nNo response from the api.');
      }
    }).fail(function (fail) {
      console_log_debug('Fail to find the closest residence from the user IP:\n' + fail.responseJSON[0] + '\nWaiting manual location by clicking to the locate button.'); //console.log(fail);
    });
  }
  /* If a residence is residenced in the cookie by his ID, we get back the residence data from the rest endpoint */


  function get_residence_from_id(closest_residence_id) {
    window.jQuery.getJSON('/wp-json/get_residence_info/id/' + closest_residence_id + '/' + residence_locator_data.site_lang + '/', function (residence) {
      populate_residence_data(residence);
      /**
       * @todo Faire en sorte que le cookie se rappel aussi de la LAT et LNG pour la repasser à FacetWP.
       */
      // pre_search_closest_residence_from_coordinates(latitude, longitude);

      document.cookie = "closest_residence_id=" + residence.residence_id;
      setWithExpiry('closest_residence_' + residence_locator_data.site_lang, JSON.stringify(residence), 86400000);
      /* expire in one day */

      console_log_debug('Closest residence loaded from cookie');
    }).fail(function (fail) {
      console_log_debug('Fail to find the residence from the ID saved in cookie:\n' + fail.responseJSON[0] + '\nWaiting manual location by clicking to the locate button.'); //console.log(fail);
    });
  }

  function init_event_find_closest_residence() {
    document.querySelector('#find_closest_residence').addEventListener('click', function () {
      console_log_debug('Asking if the browser can give us the location...');
      $('#spinner').show();

      if (navigator.geolocation) {
        console_log_debug('Yes he can, asking him the user location');
        navigator.geolocation.getCurrentPosition(function (position) {
          console_log_debug('User location is:\nLat: ' + position.coords.latitude + '\nLng: ' + position.coords.longitude);
          get_closest_residence_from_coordinates(position.coords.latitude, position.coords.longitude);
        }, handleLocationError, {
          timeout: 5000,
          enableHighAccuracy: false
        });
      } else {
        console_log_debug('No he don\'t, browser location not supported. \nRedirecting to residence location page.');
        window.location.href = residence_locator_data.residence_locator_page;
      }
    });
  }

  function initialize() {
    var input = document.getElementById('search_address');
    var autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.setComponentRestrictions({
      country: ["ca"]
    });
    google.maps.event.addListener(autocomplete, 'place_changed', function () {
      var place = autocomplete.getPlace();
      document.getElementById('autocompleteLat').value = place.geometry.location.lat();
      document.getElementById('autocompleteLng').value = place.geometry.location.lng();
    });
  }

  function bindEvent() {
    document.getElementById('search_address').addEventListener('input', function (ev) {
      if (0 !== this.value.length) {
        document.getElementById('locate_nearest_residence').disabled = false;
      } else {
        document.getElementById('locate_nearest_residence').disabled = true;
      }
    });
    $('#locate_nearest_residence').on("click", function () {
      console_log_debug('Clicked: locate from input');
      $('#spinner').show();
      var facetwp_lat = $('#autocompleteLat').val();
      var facetwp_lng = $('#autocompleteLng').val();
      get_closest_residence_from_coordinates(facetwp_lat, facetwp_lng);
    });
    $('#find_another_residence').on("click", function (ev) {
      $('#current_residence_manual_search').show();
      $('#find_another_residence').hide();
      $('#current_residence_content').hide();
    });
  }
  /* Process */


  $(document).ready(function () {
    if (null !== window.localStorage.getItem('closest_residence_' + residence_locator_data.site_lang)) {
      /* If residence already residenced in local storage */
      residence = JSON.parse(getWithExpiry('closest_residence_' + residence_locator_data.site_lang));
      populate_residence_data(residence);
      $('#current_residence_content').show();
      console_log_debug('Closest residence data loaded from local storage');
    } else if (document.cookie.indexOf("closest_residence_id=") >= 0) {
      /* Otherwise if the id of the residence is saved in cookie */
      var closest_residence_id = document.cookie.split('; ').find(function (row) {
        return row.startsWith('closest_residence_id');
      }).split('=')[1];
      get_residence_from_id(closest_residence_id);
    } else {
      if (!isMobileDevice()) {
        /* We don't locate by IP on mobile devices */
        console_log_debug('Non mobile device detected.\nLet\'s find the closest residence from the user IP ');
        get_closest_residence_from_auto_ip();
      } else {
        console_log_debug('Mobile device detected, no store already saved. We don\'t geolocate by IP. Waiting user manual location ');
      }
    } // Initilize search autocomplete


    if ('undefined' !== typeof google) {
      google.maps.event.addDomListener(window, 'load', initialize);
    }

    init_event_find_closest_residence();
    bindEvent();
  }); //Polyfill for startsWith

  if (!String.prototype.startsWith) {
    Object.defineProperty(String.prototype, 'startsWith', {
      value: function value(search, rawPos) {
        var pos = rawPos > 0 ? rawPos : 0;
        return this.substring(pos, pos + search.length) === search;
      }
    });
  }
})(jQuery);